// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// var fileterDataType = localStorage.getItem('filterTypeData');
// var isCloudfrontEnabled  = JSON.parse(fileterDataType).is_cloudfront_enabled;
// var bucket  = JSON.parse(fileterDataType).bucket;

// export const environment = {
//   production: false,
//   api_url: 'https://appsv2.digivive.com/arpan/', // Local Url 
//   play_url:'https://appsv2.digivive.com/',
//   api_base_url:'https://appsv2.digivive.com/dev/',
//   googleTrackingCode: 'G-1QR6CKY32N',
//   BASE_ASSETS_PATH: 'https://res.cloudinary.com/digivive/image/upload/',
//   APP_CLOUD_NAME: 'v1603711950',
//   SRC_EXT :'.webp' ,
//   platformType: '1',
// };
export const environment = {
  production: false,
  api_url: 'https://stage-apps.digivive.com/arpan/', // Local Url 
  play_url:'https://stage-apps.digivive.com',
  api_base_url:'https://stage-apps.digivive.com/dev/',
  googleTrackingCode: 'G-1QR6CKY32N',
  BASE_ASSETS_PATH: 'https://res.cloudinary.com/digivive/image/upload/',
  APP_CLOUD_NAME: 'v1603711950',
  SRC_EXT :'.webp' ,
  platformType: '1',
};






/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 import 'zone.js/dist/zone-error';  // Included with Angular CLI.
